import React from 'react'
import LogoImg from '../../../assets/images/fitit-logo.png'
const Logo = (props) => {

    return (
        <>
            <img src={LogoImg} alt='Logo' width={'170'} />
        </>
    )
}

export default Logo
